import React from 'react';
import "./ContactUsBanner.css";

export default class ContactUsBanner extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (

          	<div className="container-fluid nopadding cubannerWall col-lg-12 col-md-12 col-sm-12 col-xs-12 noPadding ">
				<div className="centered ">Let's build wealth</div>
			</div>
			
		);
	}
}
